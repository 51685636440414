import * as React from 'react'
import PropTypes from 'prop-types'

import * as common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import * as icons from '../icons'
import { Amount } from '../amount'

const shine = keyframes`
  0% {
    background-position-x: left;
    color: rgba(255, 255, 255, 0.7);
    transform: scale(1.05, 1.05);
  }

  100% {
    background-position-x: right;
  }
`

const Wrapper = styled('div', {
  shouldForwardProp: common.noneOf(['animate']),
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  text-align: left;
  overflow: hidden;
  ${css({
    backgroundColor: 'menu-balance-background',
    borderBottomLeftRadius: ['9999px', '0px'],
    borderBottomRightRadius: '9999px',
    borderTopLeftRadius: ['9999px', '0px'],
    borderTopRightRadius: '9999px',
    color: 'g-text',
    fontFamily: 'head',
    fontSize: 2,
    py: [0, '10px'],
    pr: [0, '10px'],
    pl: [0, 1],
    width: ['auto', '100%'],
  })};

  &.animated {
    background-image: linear-gradient(
      140deg,
      rgba(255, 201, 86, 0) 0%,
      rgba(255, 201, 86, 0.75) 35%,
      rgba(251, 224, 167, 0.9) 36%,
      rgba(251, 224, 167, 0.9) 37%,
      rgba(255, 201, 86, 0.75) 50%,
      rgba(255, 201, 86, 0) 75%,
      rgba(255, 201, 86, 0)
    );
    background-repeat: no-repeat;
    background-size: 400% 100%;
    animation-duration: 800ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: ${shine};
  }
`

const VisibilityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 75ms ease-in-out;

  ${css({
    color: 'menu-balance-visibility-toggler',
    fontSize: [2, 5],
    maxHeight: ['30px', '40px'],
    my: ['-8px', '-10px'],
    mr: ['-8px', '-10px'],
    p: ['8px', '10px'],
    '&:hover': {
      color: 'menu-balance-visibility-toggler-hover',
    },
  })};
`

const Blur = styled('div', { shouldForwardProp: common.noneOf(['blurred']) })`
  transition: filter 150ms ease-in-out;
  filter: ${props => (props.blurred ? 'blur(5px)' : 'blur(0px)')};
  padding: 8px;
  margin: -8px;
`

export function BalanceWrapper(props) {
  const [isAnimating, setIsAnimating] = React.useState(false)
  const [visible, setVisible] = React.useState(true)
  const prevBalance = React.useRef(props.amountCents)

  React.useEffect(() => {
    if (isAnimating) {
      const id = setTimeout(() => {
        setIsAnimating(false)
      }, 700)

      return () => {
        clearTimeout(id)
      }
    }
  }, [isAnimating])

  React.useEffect(() => {
    if (props.amountCents > prevBalance.current) {
      setIsAnimating(true)
    }
    prevBalance.current = props.amountCents
  }, [props.amountCents, prevBalance])

  function handleToggleVisibility(e) {
    e.preventDefault()
    setVisible(!visible)
  }

  return (
    <Wrapper className={visible && isAnimating ? 'animated' : ''}>
      {/* If balance is greater than 100k we hide the decimals */}
      <Blur blurred={!visible}>{props.children}</Blur>
      <VisibilityWrapper onClick={handleToggleVisibility}>
        {visible ? <icons.Visibility /> : <icons.VisibilityOff />}
      </VisibilityWrapper>
    </Wrapper>
  )
}

BalanceWrapper.propTypes = {
  amountCents: PropTypes.number,
  children: PropTypes.node,
}

export function Balance(props) {
  return (
    <BalanceWrapper amountCents={props.amountCents}>
      <Amount decimals={props.amountCents > 10000000 ? 'hide' : 'show'}>
        {props.amountCents}
      </Amount>
    </BalanceWrapper>
  )
}

Balance.propTypes = {
  amountCents: PropTypes.number,
  children: PropTypes.node,
}
