import { connect } from 'react-redux'

import * as supportChat from '@rushplay/support-chat'

import { SupportChatMenuItem as Component } from './support-chat-menu-item'

export const SupportChatMenuItem = connect(
  state => ({
    unreadMessages: supportChat.getUnreadCount(state.supportChat),
  }),
  dispatch => ({
    onClick: () => dispatch(supportChat.setTabVisibility(true)),
  })
)(Component)
