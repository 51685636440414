import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'
import { differenceInMinutes } from 'date-fns'

import * as emotion from '@emotion/core'
import * as Common from '@rushplay/common'
import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import * as I18n from '@rushplay/i18n'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Configuration from '../configuration'
import * as Constants from '../constants'
import * as Cookies from '../cookies-module'
import * as icons from '../icons'
import * as ServerConfig from '../server-configuration'
import * as Analytics from '../analytics'
import { Balance } from '../balance'
import { Button } from '../button'
import { Divider } from '../divider'
import { Logotype } from '../logotype'
import { LoyaltyMenuItem } from '../loyalty-menu-item'
import { LoyaltyProgramMenuProgressItem } from '../loyalty-program-menu-progress-item'
import { MenuItem } from '../menu-item'
import { MenuNotificationBadge } from '../menu-notification-badge'
import { PromotionsMenuItem } from '../promotions-menu-item'
import { ScrollLock } from '../scroll-lock'
import { SportsMenuItem } from '../sports-menu-item'
import { SupportChatMenuItem } from '../support-chat-menu-item'
import { WalletMenuItem } from '../wallet-menu-item'
import { useLocks } from '../use-locks'
import { useMenuQueries } from '../use-menu-queries'
import { usePendingTransactions } from '../pending-transactions'

const duration = 200
const defaultStyle = {
  transition: `${duration}ms ease-in-out`,
  transform: 'translateY(-100%)',
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 0, display: 'flex' },
  entered: { opacity: 1, transform: 'translateY(0)', display: 'flex' },
  exiting: { opacity: 0, transform: 'translateY(-100%)' },
  exited: { opacity: 0, display: 'none' },
}

const Wrapper = styled.header`
  ${css({
    backgroundColor: 'nav',
    position: 'sticky',
    top: '0px',
    left: '0px',
    height: [null, null, '100vh'],
    width: ['100%', null, '200px'],
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    zIndex: [110, 90],
  })};

  flex-shrink: 0;

  -webkit-overflow-scrolling: touch;

  @media (hover: hover) {
    ::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      ${props => emotion.css`
        background-color: ${props.theme.colors['nav']};
      `}
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: rgb(255, 255, 255, 0.3);
      }
    }
  }
`

const Menu = styled.div`
  ${css({
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: [null, null, 'space-between'],
    height: ['calc(var(--window-inner-height, 100vh) - 46px)', null, 'auto'],
    overflowY: ['auto', null, 'initial'],
  })};

  -webkit-overflow-scrolling: touch;

  // These styles are only to override the animation styles from small screen
  @media screen and (min-width: 1000px) {
    display: inline-flex !important;
    transform: translate(0) !important;
    opacity: 1 !important;
  }

  @media (hover: hover) {
    ::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      ${props => emotion.css`
        background-color: ${props.theme.colors['nav']};
      `}
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: rgb(255, 255, 255, 0.3);
      }
    }
  }
`

export function MainMenu(props) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const nodeRef = React.useRef(null)
  const [returningPlayer] = Cookies.useCookie('returning_player')
  const i18n = I18n.useI18n()
  const location = ReactRouter.useLocation()
  const { country, locale } = ServerConfig.useContext()
  const { hasLocks } = useLocks()
  const {
    briteLoginDepositQuery,
    depositQuery,
    withdrawalQuery,
    loyaltyQuery,
    loginQuery,
    registerQuery,
  } = useMenuQueries()
  const dispatch = ReactRedux.useDispatch()

  const manualSignupEnabled = ReactRedux.useSelector(state =>
    Jurisdiction.getManualSignUpAllowed(state.jurisdiction)
  )
  const briteSigninEnabled = ReactRedux.useSelector(state =>
    Configuration.getBriteSigninEnabled(state.configuration)
  )
  const expertBlogEnabled = ReactRedux.useSelector(state =>
    Configuration.expertBlogEnabled(state.configuration)
  )

  const { pendingTransactions } = usePendingTransactions()

  React.useEffect(() => {
    setIsMenuOpen(false)
  }, [location])

  return (
    <Wrapper data-testid="main-menu">
      {isMenuOpen && !Constants.isDesktop && <ScrollLock />}
      {/* Topbar */}
      <Common.Box
        color="g-text"
        py={[0, null, 1]}
        pl={[0, null, '0px']}
        pr={[0, null, 1]}
        backgroundColor="nav"
        position="sticky"
        top="0px"
        left="0px"
        zIndex="1"
        display="flex"
        justifyContent={['space-between', null, 'stretch']}
        alignItems={['center', null, 'stretch']}
        flexDirection={['row', null, 'column']}
        flexShrink="0"
      >
        <Common.Box display="flex" justifyContent="center" pl={[null, null, 1]}>
          <ReactRouter.Link to="/" data-testid="main-menu-logotype">
            <Logotype title="Boom Casino" />
          </ReactRouter.Link>
        </Common.Box>
        <Common.Flex>
          <Common.Box
            pt={[null, null, 2]}
            flexGrow="1"
            flexDirection={['row-reverse', null, 'column']}
            display="flex"
          >
            {props.authenticated ? (
              <ReactRouter.Link
                to={hasLocks ? '/' : `?${depositQuery}`}
                data-testid="main-menu-balance"
              >
                <Balance />
              </ReactRouter.Link>
            ) : (
              <Common.Box
                display="flex"
                flexDirection={['row-reverse', null, 'column']}
              >
                {returningPlayer && location.pathname !== '/' && (
                  <Common.Space pl={[0, null, 1]}>
                    <ReactRouter.Link
                      to={`?${loginQuery}`}
                      data-testid="main-menu-login"
                    >
                      <Button stretch variant="primary" fontSize={[1, null, 3]}>
                        {i18n.translate('main-menu.login')}
                      </Button>
                    </ReactRouter.Link>
                  </Common.Space>
                )}
                {!returningPlayer && location.pathname !== '/' && (
                  <Common.Space pt={[null, null, 1]} pl={[null, null, 1]}>
                    <ReactRouter.Link
                      to={
                        briteSigninEnabled
                          ? `?${briteLoginDepositQuery}`
                          : `?${registerQuery}`
                      }
                      data-testid={
                        briteSigninEnabled
                          ? 'main-menu-pay-and-play-register'
                          : 'main-menu-manual-register'
                      }
                    >
                      <Button
                        stretch
                        variant="secondary"
                        fontSize={[1, null, 3]}
                      >
                        {i18n.translate('main-menu.register')}
                      </Button>
                    </ReactRouter.Link>
                  </Common.Space>
                )}
              </Common.Box>
            )}
            {props.authenticated && (
              <Common.Space pr={[0, null, '0px']} pt={[null, null, 0]}>
                <ReactRouter.Link
                  to={hasLocks ? '/' : `?${loyaltyQuery}`}
                  data-testid="main-menu-loyalty-program-progress"
                  onClick={() =>
                    dispatch(
                      Analytics.cashVisit({
                        eventAction: 'Topbar',
                        title: 'main-menu-loyalty-program-progress',
                        path: `${locale.slug}${location.pathname}`,
                      })
                    )
                  }
                >
                  <LoyaltyProgramMenuProgressItem />
                </ReactRouter.Link>
              </Common.Space>
            )}
          </Common.Box>
          <Common.Box
            data-testid="main-menu-menu-toggler"
            display={['inline-flex', null, 'none']}
            alignItems="center"
            pl={0}
            fontSize="29px"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <icons.Clear />
            ) : (
              <MenuNotificationBadge>
                <icons.Menu />
              </MenuNotificationBadge>
            )}
          </Common.Box>
        </Common.Flex>
      </Common.Box>

      {/* Navigations */}
      <Transition
        nodeRef={nodeRef}
        in={isMenuOpen}
        timeout={{
          exit: 240,
        }}
      >
        {state => (
          <Menu
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            ref={nodeRef}
          >
            <nav>
              {pendingTransactions?.length > 0 ? (
                <WalletMenuItem
                  disabled={props.locked || !props.authenticated}
                  highlightColor="secondary"
                  icon={icons.CoinStack}
                  testId="main-menu-wallet-pending-withdrawals"
                  to={`?${withdrawalQuery}`}
                >
                  {i18n.translate('main-menu.pending-withdrawals')}
                </WalletMenuItem>
              ) : null}
              <WalletMenuItem
                animate={props.hasLowBalance}
                disabled={!props.authenticated}
                highlightColor="primary"
                icon={icons.BankNote}
                testId="main-menu-wallet-deposit"
                to={hasLocks ? '/' : `?${depositQuery}`}
              >
                {i18n.translate('main-menu.deposit')}
              </WalletMenuItem>
              <Common.Space py={0} px={1}>
                <Divider />
              </Common.Space>
              <MenuItem
                icon={icons.ViewModule}
                testId="main-menu-casino"
                to="/casino"
              >
                {i18n.translate('main-menu.casino')}
              </MenuItem>
              <MenuItem
                icon={icons.Videocam}
                testId="main-menu-live-casino"
                to="/live-casino"
              >
                {i18n.translate('main-menu.live-casino')}
              </MenuItem>
              <SportsMenuItem testId="main-menu-sports">
                {i18n.translate('main-menu.sport')}
              </SportsMenuItem>

              {expertBlogEnabled && (
                <MenuItem
                  icon={icons.Edit}
                  testId="main-menu-experts-area-item"
                  to="/experts-area"
                >
                  {i18n.translate('main-menu.experts-area')}
                </MenuItem>
              )}
              <PromotionsMenuItem testId="main-menu-promotion-notifications">
                {i18n.translate('main-menu.promotion-notifications')}
              </PromotionsMenuItem>
              <MenuItem
                disabled={!props.authenticated}
                icon={icons.Face}
                testId="main-menu-account"
                to="/account"
              >
                {i18n.translate('main-menu.account')}
              </MenuItem>
              <SupportChatMenuItem testId="main-menu-support-chat">
                {i18n.translate('main-menu.support-chat')}
              </SupportChatMenuItem>
              <Common.Space py={0} px={1}>
                <Divider />
              </Common.Space>
              <WalletMenuItem
                animate={props.hasLowBalance}
                disabled={!props.authenticated}
                highlightColor="primary"
                icon={icons.BankNote}
                testId="main-menu-wallet-deposit"
                to={hasLocks ? '/' : `?${depositQuery}`}
              >
                {i18n.translate('main-menu.deposit')}
              </WalletMenuItem>
              <WalletMenuItem
                disabled={!props.authenticated}
                highlightColor="secondary"
                icon={icons.CoinStack}
                testId="main-menu-wallet-withdraw"
                to={hasLocks ? '/' : `?${withdrawalQuery}`}
              >
                {i18n.translate('main-menu.withdraw')}
              </WalletMenuItem>
              <LoyaltyMenuItem
                disabled={!props.authenticated}
                testId="main-menu-loyalty-program"
                to={hasLocks ? '/' : `?${loyaltyQuery}`}
                onClick={() =>
                  dispatch(
                    Analytics.cashVisit({
                      eventAction: 'Sidebar',
                      title: `${i18n.translate('main-menu.loyalty-program')}`,
                      path: `${locale.slug}${location.pathname}`,
                    })
                  )
                }
              >
                {i18n.translate('main-menu.loyalty-program')}
              </LoyaltyMenuItem>
            </nav>
            <Common.Box
              py={1}
              pb={['50px', null, 1]}
              color="g-text"
              textAlign="center"
              fontSize={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              flexShrink="0"
            >
              {props.authenticated ? (
                <React.Fragment>
                  <Common.Box
                    opacity="0.46"
                    data-testid="main-menu.current-session-time"
                  >
                    <React.Fragment>
                      <span>
                        {i18n.translate('main-menu.current-session-time')}{' '}
                      </span>
                      <Common.Timestamp>
                        {timestamp =>
                          `${differenceInMinutes(
                            timestamp,
                            props.loggedInAt
                          )} min`
                        }
                      </Common.Timestamp>
                    </React.Fragment>
                  </Common.Box>
                  <ReactRouter.Link
                    to="/logout"
                    data-testid="main-menu-log-out"
                  >
                    <Common.Space p={0}>
                      {i18n.translate('main-menu.log-out')}
                    </Common.Space>
                  </ReactRouter.Link>
                </React.Fragment>
              ) : returningPlayer && country.enabled ? (
                <React.Fragment>
                  <Common.Box opacity="0.46">
                    {i18n.translate('main-menu.not-a-member')}
                  </Common.Box>
                  {manualSignupEnabled && (
                    <ReactRouter.Link
                      to={`?${registerQuery}`}
                      data-testid="main-menu-manual-register"
                    >
                      <Common.Space p={0}>
                        {i18n.translate('main-menu.register')}
                      </Common.Space>
                    </ReactRouter.Link>
                  )}
                  {briteSigninEnabled && (
                    <ReactRouter.Link
                      to={`?${briteLoginDepositQuery}`}
                      data-testid="main-menu-pay-and-play-register"
                    >
                      <Common.Space p={0}>
                        {i18n.translate('main-menu.register')}
                      </Common.Space>
                    </ReactRouter.Link>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Common.Box opacity="0.46">
                    {i18n.translate('main-menu.already-have-an-account')}
                  </Common.Box>
                  <ReactRouter.Link
                    to={`?${loginQuery}`}
                    data-testid="main-menu-login"
                  >
                    <Common.Space p={0}>
                      {i18n.translate('main-menu.login')}
                    </Common.Space>
                  </ReactRouter.Link>
                </React.Fragment>
              )}
            </Common.Box>
          </Menu>
        )}
      </Transition>
    </Wrapper>
  )
}

MainMenu.propTypes = {
  authenticated: PropTypes.bool,
  hasLowBalance: PropTypes.bool,
  locked: PropTypes.bool,
  loggedInAt: PropTypes.instanceOf(Date),
}
