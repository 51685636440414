import * as R from 'ramda'
import { connect } from 'react-redux'

import * as session from '../session'
import * as player from '../player'

import { MainMenu as Component } from './main-menu'

export const MainMenu = connect(state => {
  const authenticated = session.isAuthenticated(state.session)
  const balanceCents = player.getBalanceCents(state.player)

  return {
    authenticated,
    hasLowBalance: R.lt(balanceCents, 1000),
    loggedInAt: authenticated
      ? new Date(session.getCreatedAt(state.session))
      : null,
  }
})(Component)

// For @loadable/components
export default MainMenu
