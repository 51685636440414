import * as Constants from './constants'
import { useSafeUpdateQuery } from './use-safe-update-query'

export function useMenuQueries() {
  const briteLoginDepositQuery = useSafeUpdateQuery({ 'login-flow': 'deposit' })

  const depositQuery = useSafeUpdateQuery({
    wallet: Constants.TransactionType.DEPOSIT,
  })
  const withdrawalQuery = useSafeUpdateQuery({
    wallet: Constants.TransactionType.WITHDRAWAL,
  })
  const loyaltyQuery = useSafeUpdateQuery({ 'loyalty-program': 'cashback' })
  const loginQuery = useSafeUpdateQuery({ login: 'me' })
  const registerQuery = useSafeUpdateQuery({ register: 'me' })

  return {
    briteLoginDepositQuery,
    depositQuery,
    loginQuery,
    loyaltyQuery,
    registerQuery,
    withdrawalQuery,
  }
}
