import css from '@styled-system/css'
import styled from '@emotion/styled'

export const Logotype = styled.div`
  ${css({
    width: ['110px', null, '140px'],
    height: ['30px', null, '45px'],
    backgroundImage: [
      'url(/images/boom-logo-small.svg)',
      null,
      'url(/images/boom-logo.svg)',
    ],
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  })};

  @media screen and (max-width: 320px) {
    background-image: url(/images/boom-icon.svg);
    width: 30px;
    height: 30px;
  }
`
// for @loadable/components
export default Logotype
