import * as React from 'react'
import PropTypes from 'prop-types'

import * as common from '@rushplay/common'
import * as Emotion from '@emotion/core'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as icons from '../icons'
import { baseStyles } from '../menu-item'

const attentionAnimation = Emotion.keyframes`
  0%,15%, 60%, 100% {
    transform: rotate(0);
  }
  35%, 45%, 55% {
    transform: rotate(20deg);
  }
  40%, 50% {
    transform: rotate(-20deg);
  }
`

const AnimatedIcon = styled.div`
  transform: rotate(0);

  ${props => {
    if (props.hasUnreadMsgs) {
      return Emotion.css`
        animation-name: ${attentionAnimation};
        animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
        animation-duration: 3s;
        animation-iteration-count: infinite;
      `
    }
  }}
`

// Same style as other menu-items
const MenuLink = styled.div`
  ${baseStyles};
  ${css({ color: 'nav-item' })};
  cursor: pointer;
`

export function SupportChatMenuItem(props) {
  const hasUnreadMsgs = props.unreadMessages > 0

  return (
    <MenuLink data-testid={props.testId} onClick={props.onClick}>
      <AnimatedIcon hasUnreadMsgs={hasUnreadMsgs}>
        <common.Box color={hasUnreadMsgs ? 'nav-highlight' : null}>
          <icons.Chat />
        </common.Box>
      </AnimatedIcon>
      <common.Box
        fontSize={3}
        paddingLeft={1}
        display="inline-flex"
        color="g-text"
        fontFamily="head"
      >
        {props.children}
      </common.Box>
    </MenuLink>
  )
}

SupportChatMenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  unreadMessages: PropTypes.number,
  testId: PropTypes.string,
  onClick: PropTypes.func,
}
