import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Emotion from '@emotion/core'
import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as CombinedSelectors from './combined-selectors'
import * as Icons from './icons'
import * as Inventory from './inventory'
import { baseStyles } from './menu-item'
import { useLocks } from './use-locks'
import { useSafeUpdateQuery } from './use-safe-update-query'

const attentionAnimation = Emotion.keyframes`
  0%,15%, 60%, 100% {
    transform: rotate(0);
  }
  35%, 45%, 55% {
    transform: rotate(20deg);
  }
  40%, 50% {
    transform: rotate(-20deg);
  }
`

const AnimatedIcon = styled.div`
  transform: rotate(0);
  ${props => {
    if (props.shouldAnimate) {
      return Emotion.css`
        animation-name: ${attentionAnimation};
        animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
        animation-duration: 3s;
        animation-iteration-count: infinite;
      `
    }
  }}
`

const DisabledLink = styled.span`
  ${baseStyles};
  ${css({ color: 'g-text' })};
  opacity: 0.35;
  cursor: not-allowed;

  &:hover,
  &:active {
    background-color: initial;
    box-shadow: initial;
  }
`

const MenuLink = styled(ReactRouter.Link, {
  shouldForwardProp: Common.noneOf(['highlighted']),
})`
  ${baseStyles};
  ${props => css({ color: props.highlighted ? 'nav-highlight' : 'nav-item' })};
  transition: color 150ms ease-in-out;

  &:active {
    ${props => Emotion.css`
      box-shadow: inset 4px 0 0 0 ${props.theme.colors['nav-highlight']};
    `}
  }
`

export function PromotionsMenuItem(props) {
  const shouldAnimate = ReactRedux.useSelector(
    CombinedSelectors.hasPromotionNotifications
  )
  const { hasLocks } = useLocks()
  const hasUnseenInventory = ReactRedux.useSelector(state =>
    Inventory.hasUnseenItems(state.inventory)
  )
  const promotionsQuery = useSafeUpdateQuery({
    promotions: hasUnseenInventory ? 'inventory' : 'campaigns',
  })

  if (props.disabled) {
    return (
      <DisabledLink data-testid={props.testId}>
        <Icons.Notifications />
        <Common.Box
          fontSize={3}
          paddingLeft={1}
          display="inline-flex"
          color="g-text"
          fontFamily="head"
        >
          {props.children}
        </Common.Box>
      </DisabledLink>
    )
  }

  return (
    <MenuLink
      data-testid={props.testId}
      to={hasLocks ? '/' : `?${promotionsQuery}`}
      onClick={props.onClick}
      highlighted={shouldAnimate}
    >
      <AnimatedIcon shouldAnimate={shouldAnimate}>
        <Icons.Notifications />
      </AnimatedIcon>
      <Common.Box
        fontSize={3}
        paddingLeft={1}
        display="inline-flex"
        color="g-text"
        fontFamily="head"
      >
        {props.children}
      </Common.Box>
    </MenuLink>
  )
}

PromotionsMenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
  onClick: PropTypes.func,
}

export default PromotionsMenuItem
