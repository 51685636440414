import * as R from 'ramda'
import * as React from 'react'
import { useSelector } from 'react-redux'

import * as common from '@rushplay/common'

import * as player from './player'
import { LoyaltyProgramRoundProgressBar } from './loyalty-program-round-progress-bar'

export function LoyaltyProgramMenuProgressItem() {
  const currentSpin = R.defaultTo(
    0,
    useSelector(state => player.getBetBackProgress(state.player))
  )
  const cycleLength = R.defaultTo(
    0,
    useSelector(state => player.getBetBackDropPoint(state.player))
  )

  return (
    <common.Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      fontWeight="700"
      backgroundColor="menu-loyalty-background"
      borderBottomLeftRadius={['9999px', '0px']}
      borderBottomRightRadius="9999px"
      borderTopLeftRadius={['9999px', '0px']}
      borderTopRightRadius="9999px"
      color="g-text"
      fontFamily="head"
      fontSize={2}
      py={['4px', 0]}
      px={['4px', 1]}
      pr={['4px', '8px']}
      textAlign="left"
      width={['auto', '100%']}
    >
      <common.Box display={['none', 'inline-flex']}>
        {`${currentSpin}/${cycleLength}`}
      </common.Box>
      <common.Box pl={[null, 0]}>
        <LoyaltyProgramRoundProgressBar />
      </common.Box>
    </common.Box>
  )
}
