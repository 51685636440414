import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as Emotion from '@emotion/core'
import * as common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import { baseStyles } from './menu-item'

const attentionAnimation = Emotion.keyframes`
  0%,15%, 60%, 100% {
    transform: rotate(0);
  }
  35%, 45%, 55% {
    transform: rotate(20deg);
  }
  40%, 50% {
    transform: rotate(-20deg);
  }
`

const AnimatedIcon = styled.div`
  transform: rotate(0);
  ${props => {
    if (props.shouldAnimate) {
      return Emotion.css`
        animation-name: ${attentionAnimation};
        animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
        animation-duration: 3s;
        animation-iteration-count: infinite;
      `
    }
  }}
`
const DisabledWalletLink = styled.span`
  ${baseStyles};
  ${css({ color: 'g-text' })};
  opacity: 0.35;
  cursor: not-allowed;

  &:hover,
  &:active {
    background-color: initial;
    box-shadow: initial;
  }
`

const WalletLink = styled(ReactRouter.Link)`
  ${baseStyles};
  ${props =>
    css({
      color: props.color,
      '&:active': {
        boxShadow: `inset 4px 0 0 0 ${props.color}`,
      },
    })};

  ${props => Emotion.css`
    &:active {
      box-shadow: inset 4px 0 0 0 ${props.theme.colors[props.color]};
    }
  `}
`

export function WalletMenuItem(props) {
  const Icon = props.icon

  if (props.disabled) {
    return (
      <DisabledWalletLink data-testid={props.testId}>
        <Icon />
        <common.Box
          fontSize={3}
          paddingLeft={1}
          display="inline-flex"
          color="g-text"
        >
          {props.children}
        </common.Box>
      </DisabledWalletLink>
    )
  }

  return (
    <WalletLink
      data-testid={props.testId}
      color={props.highlightColor}
      to={props.to}
      onClick={props.onClick}
    >
      <AnimatedIcon shouldAnimate={props.animate}>
        <Icon />
      </AnimatedIcon>
      <common.Box
        fontSize={3}
        paddingLeft={1}
        display="inline-flex"
        color="g-text"
      >
        {props.children}
      </common.Box>
    </WalletLink>
  )
}

WalletMenuItem.propTypes = {
  animate: PropTypes.bool,
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  highlightColor: PropTypes.string,
  icon: PropTypes.func.isRequired,
  testId: PropTypes.string,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}
